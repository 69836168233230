// this function checks if the is Super Admin
// in Pro Team, only Temurbek Muhtorov is Super Admin
// 64359779148b68b2a3f46d42 - is the _id of user Temurbek Muhtorov
// 643597709bc52ed32509691d - is the _id of user Jalol
const userIsSuperAdmin = (user = {}) => {
  return user && user.hasOwnProperty('isSuperAdmin') && user.isSuperAdmin === true;
  // return user.id === '64359779148b68b2a3f46d42' || user.id === '643597709bc52ed32509691d';
  // return user.name === 'Temurbek Mukhtorov' && user.email === 'temur@prodez.uz';
};

export default userIsSuperAdmin;