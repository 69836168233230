import {
  LOADING_ALL_MATERIALS,
  GET_ALL_MATERIALS,
  GET_MATERIAL_BY_ID,
} from '../actions/types';


const initialState = {
  materials: [],
  materialById: {},
  loading: false,
};


export default function (state = initialState, action) {
  switch (action.type) {
    // LOADING_ALL_MATERIALS касается не только загрузки всех материалов, но и одного материала по id
    case LOADING_ALL_MATERIALS:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_MATERIALS:
      return {
        ...state,
        materials: action.payload,
        loading: false,
      };

    case GET_MATERIAL_BY_ID:
      return {
        ...state,
        materialById: action.payload,
        loading: false,
      };

    default: return state;
  }
}