import axios from 'axios';
import { toast } from 'react-toastify';
import { getOrdersCacheObject } from '../utils/getOrdersFromCache';

import {
  CORPORATE_CLIENTS,
  GET_ALL_USERS,
  GET_DISINFECTORS,
  SET_LOADING,
  SET_LOADING_REPEAT_ORDER,
  GET_ERRORS,
  GET_ALL_ORDERS,
  GET_ORDER_BY_ID,
  CLEAR_ORDER_BY_ID,
  SEARCH_ORDERS,
  GET_ORDER_HISTORY,
  SET_SEARCH_ORDER_METHOD,
  GET_REPEAT_ORDER_FORM,
  GET_COMPLETE_ORDERS_IN_MONTH,
  SET_COMP_ORDER_METHOD_INPUT,

  GET_SORTED_ORDERS_ADMIN,
  GET_SORTED_ORDERS,
  GET_SORTED_ORDERS_SUBADMIN,
  BACK_BUTTON_ENABLED,
  SET_ADDRESS_AUTOCOMPLETE,
  // ADD_ORDER,
} from './types';


// get corporate clients
export const getCorporateClients = () => async (dispatch) => {
  const object = { method: 'corporate' };

  try {
    const res = await axios.post('/admin/search-clients', { object });

    dispatch({
      type: CORPORATE_CLIENTS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getCorporateClients',
      message: 'Не удалось получить корпоративные клиенты. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: CORPORATE_CLIENTS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// get all users
export const getAllUsers = () => async (dispatch) => {
  dispatch(setLoading());

  const object = {
    method: 'all',
    roles: [],
  };

  try {
    const res = await axios.post('/get-users', { object });

    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getAllUsers',
      message: 'Не удалось получить пользователей. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ALL_USERS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
}


// get disinfectors and subadmins
export const getDisinfectors = () => async (dispatch) => {
  dispatch(setLoading());

  const object = {
    method: 'role',
    roles: ['disinfector', 'subadmin'],
  };

  try {
    const res = await axios.post('/get-users', { object });

    dispatch({
      type: GET_DISINFECTORS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getDisinfectors',
      message: 'Не удалось получить пользователей. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_DISINFECTORS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// create new order
export const createOrder = (newOrder, history, occupation) => async (dispatch) => {
  try {
    await axios.post('/order/create-order', newOrder);

    clearSortedOrders(dispatch);

    return history.push(`/${occupation}`);

  } catch (err) {
    // console.log('createOrder catch err =', err.response.data);
    const error = {
      info: 'createOrder',
      message: 'Не удалось создать заказ. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};


export const getAddressFromPhone = (object) => async (dispatch) => {
  try {
    const res = await axios.post('/order/get-address-from-phone', { object });

    dispatch(setAddressAutocomplete({
      address_is_found: res.data.address_is_found,
      address: res.data.object.address,
      address_v2: res.data.object.address_v2,
    }));

  } catch (error) {
    // clear address_autocomplete field
    dispatch(setAddressAutocomplete({}));
  }
};


export const setAddressAutocomplete = ({ address_is_found = false, address = '', address_v2 = {} } = {}) => (dispatch) => {
  dispatch({
    type: SET_ADDRESS_AUTOCOMPLETE,
    payload: { address_is_found, address, address_v2, },
  });
};


// get order to create repeated order
export const getRepeatOrderForm = (id) => async (dispatch) => {
  dispatch(setLoadingRepeatOrder());

  try {
    const res = await axios.post('/operator/repeat-order-form', { id });

    dispatch({
      type: GET_REPEAT_ORDER_FORM,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getRepeatOrderForm',
      message: 'Не удалось получить информацию о заявке. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_REPEAT_ORDER_FORM,
      payload: {
        disinfectorId: {},
        previousOrder: {},
        userCreated: {},
      },
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const createRepeatOrder = (order, history, occupation) => async (dispatch) => {
  try {
    await axios.post('/order/create-repeat-order', { order });

    return history.push(`/${occupation}`);

  } catch (e) {
    const error = {
      info: 'createRepeatOrder',
      message: 'Не удалось создать повторную продажу. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


export const getOrderForEdit = (id) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const res = await axios.post('/order/get-order-by-id', { id });

    dispatch({
      type: GET_ORDER_BY_ID,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getOrderForEdit',
      message: 'Не удалось получить информацию о заявке. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// edit order
export const editOrder = (order, history, occupation) => async (dispatch) => {
  try {
    await axios.post('/order/edit', { order });

    // see comments for the clearSortedOrders function
    clearSortedOrders(dispatch);

    return history.push(`/${occupation}`);

  } catch (err) {
    const error = {
      info: 'editOrder',
      message: 'Не удалось редактировать заказ. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};


// new delete order function
export const deleteOrder_v2 = (object, history, occupation) => async (dispatch) => {
  try {
    await axios.post('/order/delete-order-v2', { object });

    // see comments for the clearSortedOrders function
    clearSortedOrders(dispatch);

    return history.push(`/${occupation}`);

  } catch (e) {
    const error = {
      info: 'deleteOrder_v2',
      message: 'Не удалось удалить заказ. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// get orders for logged in disinfector
export const getOrders = (userId) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const res = await axios.post('/order/get-my-orders', { userId });

    dispatch({
      type: GET_ALL_ORDERS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'getOrders',
      message: 'Не удалось получить заявки. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: GET_ALL_ORDERS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// add disinfector comment to order
export const addDisinfectorComment = (object, history, occupation) => async (dispatch) => {
  try {
    await axios.post('/order/addDisinfectorComment', object);

    return history.push(`/${occupation}`);

  } catch (e) {
    const error = {
      info: 'addDisinfectorComment',
      message: 'Не удалось добавить комментарий к заявке. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// get one order by id
export const getOrderById = (id) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const res = await axios.post('/order/get-order-by-id', { id });

    dispatch({ type: GET_ORDER_BY_ID, payload: res.data });

  } catch (e) {
    const error = {
      info: 'getOrderById',
      message: 'Не удалось получить информацию о заявке. Попробуйте еще раз',
    };

    dispatch({ type: GET_ERRORS, payload: error });

    const cacheResponse = getOrdersCacheObject();

    // find order with this id in cache
    const foundOrder = cacheResponse.orders.find(order => order._id === id);

    if (foundOrder) {
      dispatch({ type: GET_ORDER_BY_ID, payload: foundOrder });

      // close previous toasts
      toast.dismiss();
      toast.warning('Данные получены из кэша');
    }
  }
};


// search orders
export const searchOrders = (object) => async (dispatch) => {
  dispatch(setLoading());

  // search search method and search input value
  dispatch(setSearchOrderMethod(object));

  try {
    const res = await axios.post('/order/search-orders', { object });

    dispatch({
      type: SEARCH_ORDERS,
      payload: res.data,
    });

  } catch (e) {
    const error = {
      info: 'searchOrders',
      message: 'Не удалось получить заявки. Попробуйте еще раз',
    };

    toast.error(error.message);

    dispatch({
      type: SEARCH_ORDERS,
      payload: [],
    });

    dispatch({ type: GET_ERRORS, payload: error });
  }
};


// GET_ORDER_HISTORY
export const getOrderHistory = (object) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const res = await axios.post('/order/history', { object });

    dispatch({
      type: GET_ORDER_HISTORY,
      payload: res.data.data,
    });

  } catch (error) {
    toast.error('Произошла ошибка при загрузке истории заказа');

    const dummy_object = {
      initialOrder: {},
      history: [],
    };

    dispatch({
      type: GET_ORDER_HISTORY,
      payload: dummy_object,
    });
  }
};


// submit completed order
export const submitCompleteOrder = (object, history, occupation) => (dispatch) => {
  axios.post('/order/submit-complete-order', { order: object })
    .then(() => {
      dispatch({ type: BACK_BUTTON_ENABLED, payload: true });
      return history.push(`/${occupation}`);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// set this.props.order.orderById to empty order object (this is used in OrderComplete component)
export const clearOrderById = () => (dispatch) => {
  dispatch({ type: CLEAR_ORDER_BY_ID });
}


// disinfectors gets his completed orders for some month
export const getCompleteOrdersInMonth = (object) => (dispatch) => {
  dispatch({
    type: SET_COMP_ORDER_METHOD_INPUT,
    payload: object
  });

  dispatch(setLoading());
  axios.post('/order/get-complete-order-in-month', { object })
    .then(res =>
      dispatch({
        type: GET_COMPLETE_ORDERS_IN_MONTH,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );

};


export const getTgNotifsOnExpiredOrders = (object) => async (dispatch) => {
  try {
    await axios.post('/order/get-tg-notifs-for-guarantee-exp-orders', { object });

    // console.log('success tg notif');

    toast.success('Сообщение отправлено по телеграм!');
  } catch (err) {
    toast.error('Произошла ошибка при отправке сообщения');
    dispatch({ type: GET_ERRORS, payload: err, });
  }
};


// set search order method and input
export const setSearchOrderMethod = (object) => {
  return {
    type: SET_SEARCH_ORDER_METHOD,
    payload: object
  };
};


// when new order is created/edited/deleted, admin/operator/subadmin get redirected to their calendar page. In that page, we have to load sortedOrders again, because we want to see changes (changes = created/edited/deleted order) right away. To load sortedOrders again, we have set the sortedOrders field in reducer to empty array
export const clearSortedOrders = (dispatch) => {
  // for admin
  dispatch({
    type: GET_SORTED_ORDERS_ADMIN,
    payload: []
  });

  // for operator
  dispatch({
    type: GET_SORTED_ORDERS,
    payload: []
  });

  // for subadmin
  dispatch({
    type: GET_SORTED_ORDERS_SUBADMIN,
    payload: []
  });
};


export const clearErrors = () => (dispatch) => {
  dispatch({ type: GET_ERRORS, payload: {} });
};


// Disinfector loading
export const setLoading = () => {
  return {
    type: SET_LOADING
  };
};

export const setLoadingRepeatOrder = () => {
  return {
    type: SET_LOADING_REPEAT_ORDER
  };
}